import {
  buildColumns,
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  MarqueeSelection,
  Panel,
  PanelType,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  DefaultButton,
  Label
} from "@fluentui/react";
import { Loading } from "components/Core/Loading";
import { IClientAppListEntry } from "models/IClientAppListEntry";
import { IProduct } from "models/IProduct";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { ClientAppForm, ClientAppFormMode } from "./ClientAppForm";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import Logger from "Logger";
import {
  onRenderRowWithCompany,
  renderItemColumnWithoutCompanyWarning
} from "components/Core/DetailsListUtils";
import { AddAccessToExistingClientFormNavigation } from "./AddAccessToExistingClientFormNavigation";
import { PAGE_SIZE } from "helpers/const";
import { ClientAppBasicAuthPanel } from "./ClientAppBasicAuthMappingPanel";

interface IClientAppsProps {
  visible: boolean;
  onDismiss: () => void;
  product: IProduct | undefined;
  loading: boolean;
}

export const ClientAppPanel = (props: IClientAppsProps) => {
  const ctx = React.useContext(context)!;
  const [selectedClientApp, setSelectedClientApp] =
    useState<IClientAppListEntry>();
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentClientApps, setCurrentClientApps] = useState<
    IClientAppListEntry[]
  >([]);
  const [filteredClientApps, setFilteredClientApps] = useState<
    IClientAppListEntry[]
  >([]);
  const [filteredClientAppsPage, setFilteredClientAppsPage] = useState<
    IClientAppListEntry[]
  >([]);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [showConfigureBasicAuthMappingPanel, setShowConfigureBasicAuthMappingPanel] = useState<boolean>(false);
  const [showAddToExistingForm, setShowAddToExistingForm] =
    useState<boolean>(false);
  const [
    backendDefinedForThisEnvironment,
    setBackendDefinedForThisEnvironment
  ] = useState<boolean>(
    ctx.apimEnvironment === "DEV"
      ? props.product?.backendAppIdForDevApim !== null &&
          props.product?.backendAppIdForDevApim !== ""
      : ctx.apimEnvironment === "TEST"
      ? props.product?.backendAppIdForTestApim !== null &&
        props.product?.backendAppIdForTestApim !== ""
      : ctx.apimEnvironment === "PROD"
      ? props.product?.backendAppIdForProdApim !== null &&
        props.product?.backendAppIdForProdApim !== ""
      : false
  );
  const [clientAppSelection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelectedClientApp(
          clientAppSelection.getSelection()[0] as IClientAppListEntry
        );
      }
    }) as any
  );

  const [searchText, setSearchText] = useState("");
  const [
    isDeleteAppConfirmationDialogVisible,
    setIsDeleteAppConfirmationDialogVisible
  ] = useState(false);

  const [
    isDisableAppConfirmationDialogVisible,
    setIsDisableAppConfirmationDialogVisible
  ] = useState(false);

  const [
    isEnableAppConfirmationDialogVisible,
    setIsEnableAppConfirmationDialogVisible
    ] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.product?.clientApps) {
      setCurrentClientApps(props.product.clientApps);
      setFilteredClientApps(props.product.clientApps);
      clientAppSelection.setAllSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.product?.clientApps]);

  useEffect(() => {
    setBackendDefinedForThisEnvironment(
      ctx.apimEnvironment === "DEV"
        ? props.product?.backendAppIdForDevApim !== null &&
            props.product?.backendAppIdForDevApim !== ""
        : ctx.apimEnvironment === "TEST"
        ? props.product?.backendAppIdForTestApim !== null &&
          props.product?.backendAppIdForTestApim !== ""
        : ctx.apimEnvironment === "PROD"
        ? props.product?.backendAppIdForProdApim !== null &&
          props.product?.backendAppIdForProdApim !== ""
        : false
    );
  }, [
    ctx.apimEnvironment,
    props.product?.backendAppIdForDevApim,
    props.product?.backendAppIdForProdApim,
    props.product?.backendAppIdForTestApim
  ]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    clientAppSelection.setAllSelected(false);
    const offset = currentPage * PAGE_SIZE;
    setFilteredClientAppsPage(
      filteredClientApps.slice(offset, offset + PAGE_SIZE)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filteredClientApps]);

  // search and filtering
  useEffect(() => {
    if (currentClientApps) {
      clientAppSelection.setAllSelected(false);
      const newFilteredClientApps = currentClientApps
        .slice()
        .filter((clientApp) => {
          if (
            trimLower(clientApp.displayName)?.indexOf(trimLower(searchText)) >
              -1 ||
            trimLower(clientApp.appId)?.indexOf(trimLower(searchText)) > -1 ||
            trimLower(clientApp.originalRequestor)?.indexOf(
              trimLower(searchText)
            ) > -1
          ) {
            return true;
          }
          return false;
        });
      setFilteredClientApps(newFilteredClientApps);
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentClientApps]);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "Create new client",
      iconProps: { iconName: "Add" },
      onClick: () => setShowCreateForm(true),
      disabled: !backendDefinedForThisEnvironment
    },
    {
      key: "addexisting",
      text: "Add permissions for existing client",
      iconProps: { iconName: "Add" },
      onClick: () => setShowAddToExistingForm(true),
      disabled: !backendDefinedForThisEnvironment
    },
    {
      key: "edit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => setShowEditForm(true),
      disabled:
        selectedClientApp === undefined || !backendDefinedForThisEnvironment
    },
    {
      key: "configureBasicAuthMapping",
      text: "Configure Basic Auth Mapping",
      iconProps: { iconName: "AzureKeyVault" },
      onClick: () => {setShowConfigureBasicAuthMappingPanel(true)},
      disabled:
        selectedClientApp === undefined
    },
    {
      key: "enable",
      text: "Enable",
      iconProps: { iconName: "SyncOccurence" },
      onClick: () => {
        setIsEnableAppConfirmationDialogVisible(true);
      },
      disabled: selectedClientApp === undefined,
      style: {
        display:
          !selectedClientApp?.enabled
            ? "inherit"
            : "none"
      }
    },
    {
      key: "disable",
      text: "Disable",
      iconProps: { iconName: "UnsyncOccurence" },
      onClick: () => {
        setIsDisableAppConfirmationDialogVisible(true);
      },
      disabled: selectedClientApp === undefined
    },
    {
        key: "addNewSecret",
        text: "Add New Secret",
        iconProps: { iconName: "Permissions" },
        onClick: () => {
            selectedClientApp && addNewClientAppSecret(selectedClientApp.objectId);
        },
        disabled: selectedClientApp === undefined
    },
    {
      key: "delete",
      text: "Delete",
      iconProps: { iconName: "delete" },
      onClick: () => {
        setIsDeleteAppConfirmationDialogVisible(true);
      },
      disabled: selectedClientApp === undefined || !ctx.isLoggedInUserPOPAdmin,
      style: { display: ctx.isLoggedInUserPOPAdmin ? "inherit" : "none" }
    }
    // TODO: implement revoking all permissions for a product from client and discuss if the client should be deleted when last permissions are revoked
  ];

  // build DetailsList columns
  useEffect(() => {
    if (columns.length < 1) {
      let newcolumns = buildColumns(currentClientApps);
      newcolumns = newcolumns.filter(
        (column) =>
          column.key !== "objectId" &&
          column.key !== "servicePrincipalId" &&
          column.key !== "secretName" &&
          column.key !== "apimEnvironment"
      );
      for (const column of newcolumns) {
        column.isResizable = true;
        switch (column.key) {
          case "appId":
            column.name = "AppId (ClientId)";
            break;
          case "displayName":
            column.name = "Name";
            break;
          case "enabled":
            column.name = "State";
            column.onRender = (clientApp) =>
              clientApp.enabled ? "Enabled" : "Disabled";
            break;
          case "company":
            column.name = "Company";
        }
      }
      setColumns(newcolumns);
    }
  }, [currentClientApps, columns.length]);

  const deleteClientApp = (clientAppObjectId: string) => {
    if (props.product) {
      setLoading(true);
      ctx.backendClient
        .deleteClientApp(props.product.id, clientAppObjectId)
        .then(() => {
          var updatedCurrentClientApps = currentClientApps.filter(
            (ca) => ca.objectId !== clientAppObjectId
          );
          setCurrentClientApps(updatedCurrentClientApps);

          Logger.Success("Client app successfully deleted.");
        })
        .catch((error) => {
          Logger.Error(error);
        })
        .finally(() => {
          clientAppSelection.setAllSelected(false);
          setLoading(false);
        });
    }
  };

  const disableClientApp = (clientAppObjectId: string) => {
    if (props.product) {
      setLoading(true);
      ctx.backendClient
        .disableClientApp(props.product.id, clientAppObjectId)
        .then(() => {
          var indexOfUpdatedClientApp = currentClientApps.findIndex(
            (ca) => ca.objectId === clientAppObjectId
          );
          currentClientApps[indexOfUpdatedClientApp].enabled = false;
          setCurrentClientApps(currentClientApps);

          Logger.Success("Client app has been successfully disabled.");
        })
        .catch((error) => {
          Logger.Error(error);
        })
        .finally(() => {
          clientAppSelection.setAllSelected(false);
          setLoading(false);
        });
    }
    };

    const addNewClientAppSecret = (clientAppObjectId: string) => {
        if (props.product) {
            setLoading(true);
            ctx.backendClient
                .addNewClientAppSecret(props.product.id, clientAppObjectId)
                .then(() => {
                    Logger.Success("New secret created and sent to original requestor");
                })
                .catch((error) => {
                    Logger.Error({ message: "Secret could not be created, likely because maximum number of secrets reached. We sent you an E-Mail with further explanations." });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

  const enableClientApp = (clientAppObjectId: string) => {
    if (props.product) {
      setLoading(true);
      ctx.backendClient
        .enableClientApp(props.product.id, clientAppObjectId)
        .then(() => {
          var indexOfUpdatedClientApp = currentClientApps.findIndex(
            (ca) => ca.objectId === clientAppObjectId
          );
          currentClientApps[indexOfUpdatedClientApp].enabled = true;
          setCurrentClientApps(currentClientApps);

          Logger.Success("Client app has been successfully enabled.");
        })
        .catch((error) => {
          Logger.Error(error);
        })
        .finally(() => {
          clientAppSelection.setAllSelected(false);
          setLoading(false);
        });
    }
  };

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredClientApps) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedFilteredClientApps = copyAndSort(
        filteredClientApps,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredClientApps(newlySortedFilteredClientApps);
    }
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPage(page.selected);
  };

  return (
    <div>
      <Panel
        headerText={
          !showCreateForm && !showEditForm
            ? `${props.product?.displayName ?? ""} - OAuth 2.0 Client Apps`
            : ""
        }
        isOpen={props.visible}
        onDismiss={() => {
          setShowCreateForm(false);
          setShowEditForm(false);
          setShowAddToExistingForm(false);
          setSearchText("");
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth="1200px"
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /*ignored*/
        }}
      >
        <Loading loading={props.loading || loading} />

        {!showCreateForm && !showEditForm && !showAddToExistingForm && (
          <div>
            <Label style={{ fontSize: "20px" }}>
              Environment:{" "}
              <span
                style={{
                  color: `${
                    ctx.apimEnvironment === "DEV"
                      ? "#1FFF5A"
                      : ctx.apimEnvironment === "TEST"
                      ? "orange"
                      : "#95233A"
                  }`
                }}
              >
                {ctx.apimEnvironment}
              </span>
            </Label>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <SearchBox
                styles={{ root: { width: 300 } }}
                placeholder="Search"
                value={searchText ?? ""}
                onEscape={() => {
                  setSearchText("");
                  setCurrentPage(0);
                }}
                onClear={() => {
                  setSearchText("");
                  setCurrentPage(0);
                }}
                onChange={(_, newSearchText) => {
                  setSearchText(newSearchText ?? "");
                  setCurrentPage(0);
                }}
                onSearch={(newSearchText) => {
                  setSearchText(newSearchText ?? "");
                  setCurrentPage(0);
                }}
              />
              {!backendDefinedForThisEnvironment && (
                <Label
                  style={{
                    marginTop: 15,
                    backgroundColor: "#ffeecc",
                    width: "100%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                >
                  <span style={{ fontWeight: 700, color: "red" }}>
                    The backend for this product on environment{" "}
                    {ctx.apimEnvironment} has not yet been defined. Please use
                    "OAuth2 Backends" menu to define it.
                  </span>
                </Label>
              )}
              <CommandBar
                items={commandBarItems}
                styles={{ root: { marginTop: 10, padding: 0 } }}
              />
              {props.loading ||
                (filteredClientApps && (
                  <div>
                    <MarqueeSelection selection={clientAppSelection}>
                      <ShimmeredDetailsList
                        setKey="items"
                        items={filteredClientAppsPage.map((app) => {
                          return {
                            ...app,
                            company: app?.company?.name
                          };
                        })}
                        onRenderItemColumn={
                          renderItemColumnWithoutCompanyWarning
                        }
                        onRenderRow={onRenderRowWithCompany}
                        columns={columns}
                        selectionMode={SelectionMode.single}
                        enableShimmer={props.loading}
                        ariaLabelForShimmer="Loading OAuth2 client apps"
                        ariaLabelForGrid="Client apps details"
                        listProps={{
                          renderedWindowsAhead: 0,
                          renderedWindowsBehind: 0
                        }}
                        onColumnHeaderClick={onColumnClick}
                        selection={clientAppSelection}
                        selectionPreservedOnEmptyClick={true}
                      />
                    </MarqueeSelection>
                    <div>
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={Math.ceil(Math.max(filteredClientApps.length, 1) / PAGE_SIZE)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        containerClassName={"paginationContainer"}
                        previousClassName={
                          currentPage < 1 ? "disabledPagination" : ""
                        }
                        nextClassName={
                          currentPage ===
                          Math.ceil(Math.max(filteredClientApps.length, 1) / PAGE_SIZE) - 1
                            ? "disabledPagination"
                            : ""
                        }
                        activeClassName={"active"}
                        breakLabel={"..."}
                        onPageChange={onPageChanged}
                        forcePage={currentPage}
                      />
                    </div>
                  </div>
                ))}
              {!props.loading &&
                (!props.product?.clientApps ||
                  props.product.clientApps.length < 1) && (
                  <div>
                    <h5>No OAuth 2.0 client apps exist for this product.</h5>
                  </div>
                )}
            </div>
          </div>
        )}
        {showCreateForm && (
          <div>
            <ClientAppForm
              product={props.product}
              isShownInPanel={true}
              mode={ClientAppFormMode.Create}
              onCancel={() => setShowCreateForm(false)}
              onSuccess={(ca) => {
                const updatedCurrentclientApps = currentClientApps.slice();
                const createdClientApp: IClientAppListEntry = {
                  objectId: ca.id!,
                  appId: ca.appId!,
                  displayName: ca.displayName,
                  originalRequestor: ca.originalRequestorMail,
                  enabled: true, //true as when a client app is created, it is always enabled,
                  company: ca.company
                };
                setCurrentClientApps([
                  ...updatedCurrentclientApps,
                  createdClientApp
                ]);
                setShowCreateForm(false);
              }}
            />
          </div>
        )}
        {showEditForm && (
          <div>
            <ClientAppForm
              product={props.product}
              mode={ClientAppFormMode.Edit}
              clientAppIdToEdit={selectedClientApp?.objectId}
              isShownInPanel={true}
              onCancel={() => setShowEditForm(false)}
              onSuccess={(ca) => {
                const updatedClientApps = [...currentClientApps];
                const indexToUpdate = updatedClientApps.findIndex(
                  (ps) => ps.appId === ca.appId
                );
                updatedClientApps[indexToUpdate] = {
                  objectId: ca.id!,
                  appId: ca.appId!,
                  displayName: ca.displayName,
                  originalRequestor: ca.originalRequestorMail,
                  enabled: updatedClientApps[indexToUpdate].enabled,
                  company: ca.company
                };
                setCurrentClientApps(updatedClientApps);
                setShowEditForm(false);
              }}
            />
          </div>
        )}
        {showAddToExistingForm && (
          <div>
            <AddAccessToExistingClientFormNavigation
              productId={props.product?.id}
              productDisplayName={props.product?.displayName}
              onCancel={() => setShowAddToExistingForm(false)}
            />
          </div>
        )}
        {showConfigureBasicAuthMappingPanel && (
        <div>
          <ClientAppBasicAuthPanel onDismiss={() => { setShowConfigureBasicAuthMappingPanel(false)} } selectedClientApp={selectedClientApp} selectedProduct={props.product}/>
        </div>
        )}
        <Dialog
          hidden={!isDeleteAppConfirmationDialogVisible}
          onDismiss={() => setIsDeleteAppConfirmationDialogVisible(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: `Are you sure you want to delete the client app ${selectedClientApp?.displayName} from Azure Active Directory and from the database, this means that the client can not be used anymore and it can not be recovered?`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsDeleteAppConfirmationDialogVisible(false);
                if (selectedClientApp) {
                  deleteClientApp(selectedClientApp.objectId);
                }
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsDeleteAppConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDisableAppConfirmationDialogVisible}
          onDismiss={() => setIsDisableAppConfirmationDialogVisible(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: `Are you sure you want to disable the client app ${selectedClientApp?.displayName}, this means that any access to APIs from this client is disabled until it is enabled again?`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsDisableAppConfirmationDialogVisible(false);
                if (selectedClientApp) {
                  disableClientApp(selectedClientApp.objectId);
                }
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsDeleteAppConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isEnableAppConfirmationDialogVisible}
          onDismiss={() => setIsEnableAppConfirmationDialogVisible(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: `Are you sure you want to enable the client app ${selectedClientApp?.displayName}, this means that the client can be used again for OAuth2.0 purposes?`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsEnableAppConfirmationDialogVisible(false);
                if (selectedClientApp) {
                  enableClientApp(selectedClientApp.objectId);
                }
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsEnableAppConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
      </Panel>
    </div>
  );
};
