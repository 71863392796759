import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { IApi } from "models/IApi";
import { IBackendApp } from "models/IBackendApp";
import { IClientApp } from "models/IClientApp";
import { ICompany } from "models/ICompany";
import { IComplianceWarning } from "models/IComplianceWarning";
import { IComplianceWarningExceptionRequest } from "models/IComplianceWarningExceptionRequest";
import { IPerson } from "models/IPerson";
import { IProduct } from "models/IProduct";
import { IProductContract } from "models/IProductContract";
import { IProductListEntry } from "models/IProductListEntry";
import { IProductOwnershipRequestListEntry } from "models/IProductOwnershipRequestListEntry";
import { ISubscriptionListEntry } from "models/ISubscriptionListEntry";
import { IComplianceWarningStatistics } from "../models/IComplianceWarningStatistics";

export default class BackendClient {
  private readonly msalInstance: PublicClientApplication;
  private readonly apiAuthConfig: any;
 isAuthInitialized: boolean = false;

  constructor(msalInstance: PublicClientApplication) {
    this.msalInstance = msalInstance;
    const activeAccount =
      msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
    this.apiAuthConfig = {
      account: activeAccount,
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      scopes: ["api://APIMAdministratorPortal/Access"]
    };
  }

  getProduct = async (productId: string, apimEnvironment: string) => {
    return axios
      .get<IProduct>(
        `/api/product/${productId}?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
    };

  updateProduct = async (
    productId: string,
    product: IProduct,
    apimEnvironment: string
  ) => {
    return axios
      .patch(
        `/api/product/${productId}?env=${apimEnvironment}`,
        product,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  updateProductOwners = async (productId: string, productOwners: IPerson[]) => {
    return axios
      .patch(
        `/api/product/${productId}/owners`,
        productOwners,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  getProducts = async (apimEnvironment: string) => {
    return axios
      .get<IProductListEntry[]>(
        `/api/product?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data as IProductListEntry[];
      });
    };
    getComplianceWarningStatistics = async (productId: string, startDate: Date, endDate: Date) => {
        return axios
            .get<IComplianceWarningStatistics[]>(
                `/api/ComplianceWarning/ComplianceWarningStatistics?productId=${productId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                await this.getAuthorizeAndXSRFHeaders()
            )
            .then((res) => {
                return res.data as IComplianceWarningStatistics[];
            });
    };

  getProductsWithoutOwners = async (apimEnvironment: string) => {
    return axios
      .get<IProductContract[]>(
        `/api/product/withoutowners?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data as IProductContract[];
      });
  };

  createProductOwnershipRequest = async (productIdToCreate: string) => {
    return axios
      .post(
        `/api/productownershiprequest`,
        { productId: productIdToCreate },
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  setProductOwnershipRequestStatus = async (
    productOwnershipRequestId: string,
    approve: boolean
  ) => {
    let updateUrl = `/api/productownershiprequest/${productOwnershipRequestId}/approve`;
    if (!approve) {
      updateUrl = `/api/productownershiprequest/${productOwnershipRequestId}/reject`;
    }
    return axios
      .post(updateUrl, {}, await this.getAuthorizeAndXSRFHeaders())
      .then((res) => {
        return res;
      });
  };

  getProductOwnershipRequests = async () => {
    return axios
      .get<IProductOwnershipRequestListEntry[]>(
        `/api/productownershiprequest`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data as IProductOwnershipRequestListEntry[];
      });
  };

  getBackendApp = async (
    productId: string, 
    apimEnvironment: string
  ) => {
    return axios
      .get(
        `/api/product/${productId}/backendApp?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      ).then((res) => {
          const result = res.data as IBackendApp;
          result.appRoles = result?.appRoles?.map(item => { return { ...item, type: "AppRole" }; });
          result.scopes = result?.scopes?.map(item => { return { ...item, type: "UserScope" }; });
          return result;
      });
    };

  getclientappsByScope = async (
    productId: string,
    deletedScope: string
  ) => {
    return await axios
        .get(
            `/api/product/${productId}/backendApp/getclientapps?deletedscope=${deletedScope}`,
            await this.getAuthorizeAndXSRFHeaders()
        )
        .then((res) => {
            return res.data as IClientApp[];
        });
    };

  createBackendApp = async (
    productId: string,
    apimEnvironment: string,
    backendApp: IBackendApp
  ) => {
    return axios
      .post(
        `/api/product/${productId}/backendapp?env=${apimEnvironment}`,
        backendApp,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data as IBackendApp;
      });
  };

  updateBackendApp = async (
    productId: string,
    apimEnvironment: string,
    backendApp: IBackendApp
  ) => {
    return axios
      .patch(
        `/api/product/${productId}/backendapp?env=${apimEnvironment}`,
        backendApp,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  setUseSameBackendForTestAsForDev = async (
    productId: string,
    useSame: boolean
  ) => {
    if (useSame) {
      return axios
        .post(
          `/api/product/${productId}/backendapp/usesameasdev`,
          {},
          await this.getAuthorizeAndXSRFHeaders()
        )
        .then((res) => {
          return res.data;
        });
    } else {
      return axios
        .post(
          `/api/product/${productId}/backendapp/removetestbackendassignment`,
          {},
          await this.getAuthorizeAndXSRFHeaders()
        )
        .then((res) => {
          return res.data;
        });
    }
  };

  getClientApp = async (productId: string, clientId: string) => {
    return axios
      .get(
        `/api/product/${productId}/clientApp/${clientId}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        const clientApp = res.data as IClientApp;
        clientApp.isWebAppClient = clientApp.web ? true : false;
        clientApp.isPublicClient = clientApp.publicClient ? true : false;
        return clientApp;
      });
  };

  deleteClientApp = async (productId: string, clientId: string) => {
    return axios
      .delete(
        `/api/product/${productId}/clientApp/${clientId}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  enableClientApp = async (productId: string, clientId: string) => {
    return axios
      .patch(
        `/api/product/${productId}/clientApp/${clientId}/enable`,
        {},
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  disableClientApp = async (productId: string, clientId: string) => {
    return axios
      .patch(
        `/api/product/${productId}/clientApp/${clientId}/disable`,
        {},
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
    };

  addNewClientAppSecret = async (productId: string, clientId: string) => {
    return axios
        .post(
            `/api/product/${productId}/clientApp/${clientId}/addNewSecret?source=POP`,
            {},
            await this.getAuthorizeAndXSRFHeaders()
        )
        .then((res) => {
            return res;
        });
  };

  createClientApp = async (
    productId: string,
    clientApp: IClientApp,
    apimEnvironment: string
  ) => {
    return axios
      .post(
        `/api/product/${productId}/clientapp?env=${apimEnvironment}`,
        clientApp,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data as IClientApp;
      });
  };

  updateClientApp = async (productId: string, clientApp: IClientApp) => {

    // TODO: Remove this when the GraphSDK is fixed. 
    // This is a workaround for a bug where the odataType property will be wrongly set in graphclient if it comes in the request as "odataType: null"
    for(let i = 0; i < clientApp.requiredResourceAccess.length; i++) {
      if(Object.hasOwn(clientApp.requiredResourceAccess[i],"odataType")) {
        delete clientApp.requiredResourceAccess[i].odataType;
      }

      for(let j = 0; j < clientApp.requiredResourceAccess[i].resourceAccess.length; j++) {
        if(Object.hasOwn(clientApp.requiredResourceAccess[i].resourceAccess[j], "odataType")) {
          delete clientApp.requiredResourceAccess[i].resourceAccess[j].odataType;
        }
      }
    }
    
    return axios
      .patch(
        `/api/product/${productId}/clientapp`,
        clientApp,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getProductApis = async (productId: string, apimEnvironment: string) => {
    return axios
      .get<IApi[]>(
        `/api/product/${productId}/apis?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getComplianceWarnings = async (productId: string, apimEnvironment: string) => {
    return axios
      .get<IComplianceWarning[]>(
          `/api/product/${productId}/compliancewarning?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  requestComplianceWarningException = async (
    productId: string,
    exceptionRequest: IComplianceWarningExceptionRequest
  ) => {
    return axios
      .patch<IComplianceWarning>(
        `/api/product/${productId}/compliancewarning/${exceptionRequest.id}/requestexception`,
        exceptionRequest,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getAllComplianceWarningPendingExceptionRequests = async () => {
    return axios
      .get<IComplianceWarning[]>(
        "/api/compliancewarning/getpendingandapprovedexceptions",
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  approveComplianceWarningExceptionRequest = async (
    productId: string,
    complianceWarningId: string
  ) => {
    debugger;
    return axios
      .patch<IComplianceWarning>(
        `/api/product/${productId}/compliancewarning/${complianceWarningId}/approveexception`,
        null,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  rejectComplianceWarningExceptionRequest = async (
    productId: string,
    complianceWarningId: string
  ) => {
    return axios
      .patch<IComplianceWarning>(
        `/api/product/${productId}/compliancewarning/${complianceWarningId}/rejectexception`,
        null,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  revokeComplianceWarningExceptionRequest = async (
    productId: string,
    complianceWarningId: string
  ) => {
    return axios
      .patch<IComplianceWarning>(
        `/api/product/${productId}/compliancewarning/${complianceWarningId}/revokeexception`,
        null,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getProductSubscriptions = async (
    productId: string,
    apimEnvironment: string
  ) => {
    return axios
      .get<ISubscriptionListEntry[]>(
        `/api/product/${productId}/subscription?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getProductSubscription = async (
    productId: string,
    subscriptionId: string,
    apimEnvironment: string
  ) => {
    return axios
      .get<ISubscriptionListEntry>(
        `/api/product/${productId}/subscription/${subscriptionId}?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  updateProductSubscription = async (
    productId: string,
    subscriptionId: string,
    apimEnvironment: string,
    newDisplayName: string,
    newState: string,
    newCompanyId: string | undefined
  ) => {
    return axios.patch(
      `/api/product/${productId}/subscription?env=${apimEnvironment}`,
      {
        id: subscriptionId,
        displayName: newDisplayName,
        state: newState,
        companyId: newCompanyId
      },
      await this.getAuthorizeAndXSRFHeaders()
    );
  };

  deleteProductSubscription = async (
    productId: string,
    subscriptionId: string,
    apimEnvironment: string
  ) => {
    return axios
      .delete(
        `/api/product/${productId}/subscription/${subscriptionId}?env=${apimEnvironment}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res;
      });
  };

  downloadSubscriptionsCsv = async (apimEnvironment: string) => {
    return axios({
      url: `/api/product/generatesubscriptionscsv?env=${apimEnvironment}`,
      method: "GET",
      responseType: "blob",
      headers: await this.getHeaders()
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      link.setAttribute(
        "download",
        `${apimEnvironment} API Product Subscriptions - ${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  downloadClientAppsCsv = async (apimEnvironment: string) => {
    return axios({
      url: `/api/product/generateclientappscsv?env=${apimEnvironment}`,
      method: "GET",
      responseType: "blob",
      headers: await this.getHeaders()
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      link.setAttribute(
        "download",
        `${apimEnvironment} API Product Clients Apps - ${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  getClientSecret = async (clientSecretName: string) => {
    return axios
      .get(
        `/api/clientsecret/${clientSecretName}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getClientSecretAnonymous = async (clientSecretName: string) => {
    return axios
      .get(`/api/clientsecretanonymous/${clientSecretName}`)
      .then((res) => {
        return res.data;
      });
  };

  searchUsers = async (searchText: string) => {
    return axios
      .get<IPerson[]>(
        `/api/user/search/?searchText=${searchText}`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getExportProductOwners = async () => {
    return axios
      .get<string[]>(
        `api/stakeholder/productowners`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getExportApiDeployers = async () => {
    return axios
      .get<string[]>(
        `api/stakeholder/apidevelopers`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getExportCustomStakeholders = async () => {
    return axios
      .get<string[]>(
        `api/stakeholder/customstakeholders`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  getCSRFToken = async () => {
    return axios
      .get(`/api/antiforgery`, {
        headers: {
          Authorization: `Bearer ${await this.getAccessToken()}`
        }
      })
      .then((res) => {
        return res.data;
      });
  };

  getCompanies = async () => {
    return axios
      .get<ICompany[]>(`/api/company`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      });
  };

  startCompaniesSync = async () => {
    return axios
      .get(`/api/company/sync`,
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      })
  };

  disableAllClientsByCompany = async (companyId: string) => {
    return axios
      .patch(`/api/company/${companyId}/suspendallclients`, 
        {},
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      })
  };

  getBasicAuthMappingForClientApp = async (productId: string, clientAppObjectId: string, apimEnvironment: string) => {
    return axios
      .get(`/api/product/${productId}/clientApp/${clientAppObjectId}/basicAuthMapping?env=${apimEnvironment}`, 
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      })
  };

  setBasicAuthMappingForClientApp = async (productId: string, clientAppObjectId: string, backendUsername: string, backendPassword: string, apimEnvironment: string) => {
    return axios
      .patch(`/api/product/${productId}/clientApp/${clientAppObjectId}/basicAuthMapping?env=${apimEnvironment}`, 
        {username: backendUsername, password: backendPassword},
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      })
  };

  removeBasicAuthMappingForClientApp = async (productId: string, clientAppObjectId: string, apimEnvironment: string) => {
    return axios
      .delete(`/api/product/${productId}/clientApp/${clientAppObjectId}/basicAuthMapping?env=${apimEnvironment}`, 
        await this.getAuthorizeAndXSRFHeaders()
      )
      .then((res) => {
        return res.data;
      })
  };

  enableAllClientsByCompany = async (companyId: string) => {
    return axios
    .patch(`/api/company/${companyId}/reactivateallclients`,
      {},
      await this.getAuthorizeAndXSRFHeaders()
    )
    .then((res) => {
      return res.data;
    })
  }

  getAllAffectedClients = async(companyId: string) => {
    return axios
    .get(`/api/company/${companyId}/getclients`,
      await this.getAuthorizeAndXSRFHeaders()
    )
    .then((res) => {
      return res.data;
    })
  }

  isLoggedInUserPOPAdmin = async () => {
    const encodedAccessToken = await this.getAccessToken();
    const accessToken: any = jwtDecode(encodedAccessToken);
    return (
      accessToken?.roles &&
      accessToken?.roles.some((r: string) => r === "Administrator")
    );
  };

  isLoggedInUserComplianceManager = async () => {
    const encodedAccessToken = await this.getAccessToken();
    const accessToken: any = jwtDecode(encodedAccessToken);
    return (
      accessToken?.roles &&
      accessToken?.roles.some((r: string) => r === "ComplianceManager")
    );
  };

  isLoggedInSecurityAdmin = async () => {
    const encodedAccessToken = await this.getAccessToken();
    const accessToken: any = jwtDecode(encodedAccessToken);
      return (
      accessToken?.roles &&
      accessToken?.roles.some((r: string) => r === "SecurityAdmin")
    );
  };

  private getAuthorizeAndXSRFHeaders: any = async () => {
    return {
      headers: await this.getHeaders()
    };
  };

  private getHeaders: any = async () => {
    return {
      Authorization: `Bearer ${await this.getAccessToken()}`,
      "X-XSRF-TOKEN": this.getCSRFTokenFromCookie()
    };
  };

  private getCSRFTokenFromCookie = () => {
    const name = "XSRF-REQUEST-TOKEN=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    var csrfToken = "";
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        csrfToken = c.substring(name.length, c.length);
      }
    }
    if (!csrfToken || csrfToken === "") {
      this.getCSRFToken().then((_) => {
        return this.getCSRFTokenFromCookie();
      });
    }
    return csrfToken;
  };

  private getAccessToken = async () => {
    if(!this.isAuthInitialized) {
      await this.msalInstance.initialize();
      this.isAuthInitialized = true;
    }
    
    return (await this.msalInstance.acquireTokenSilent(this.apiAuthConfig))
      .accessToken;
  };
}
